@use 'abstracts' as *;

.dots {
	$self: &;
	position: fixed;

	z-index: 100;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	@include media(sm, down) {
		right: 10px;
		bottom: 10px;
		--dot-size: 20px;
		pointer-events: none;
	}

	@include media(sm) {
		left: 10px;
		top: 50%;
		transform: translateY(-50%);
		--dot-size: 24px;
	}

	@include media(lg) {
		left: 20px;
	}

	&__dot {
		background-color: transparent;
		width: var(--dot-size);
		height: var(--dot-size);
		display: block;
		position: relative;
		transition: all 0.3s;

		&::before,
		&::after {
			position: absolute;
		}

		&::before {
			content: '';
			inset: -2px;
			display: block;
			border-radius: 50%;
			background-color: rgba(var(--color-highlight-rgb), 0.35);
			backdrop-filter: blur(3px);
			transition: all 0.3s;
			opacity: 0;
			transition: all 0.75s cubic-bezier(0.68, -0.55, 0.265, 1.55);
			transform: scale(0);
		}

		&::after {
			content: '';
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 4px;
			height: 4px;
			display: block;
			border-radius: 50%;
			background-color: rgba(var(--color-highlight-rgb), 1);
			box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.3);
			transition: all 0.3s;

			@include media(sm) {
				width: 8px;
				height: 8px;
			}
		}

		&:hover:not(#{$self}__dot--active) {
			opacity: 1;
		}

		&:hover:not(#{$self}__dot--active)::before {
			opacity: 1;

			animation: pulse 0.5s ease-out;

			@keyframes pulse {
				0% {
					transform: scale(0);
					opacity: 1;
				}

				25% {
					transform: scale(1);
					opacity: 1;
				}

				100% {
					transform: scale(0.3);
				}
			}
		}

		&--active {
			&::before {
				transform: scale(1);
				opacity: 1;
			}
		}
	}
}
