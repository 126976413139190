.media {
	$self: &;

	&__picture {
		display: block;
	}

	&__img {
		width: 100%;
		max-width: 100%;
		height: auto;
	}
}
