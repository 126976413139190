@use '../variables' as *;
/*
This mixin should be included on elements that get animated
It sets the inital state and also in specific cases other styles
It also makes sure that the element is visible on browsers that do not support web animations
*/

@mixin animation-initial-background() {
	$self: &;

	#{$noWebAnimationsClass} &,
	#{$noJsClass} & {
		animation: unmask-outer 750ms forwards 500ms;

		#{$self}__inner {
			animation: unmask-inner 750ms forwards;
			clip-path: inset(0 0% 0 0);
		}

		.media {
			transition: all 500ms;
			opacity: 1;
		}

		@keyframes unmask-outer {
			0% {
				clip-path: inset(min(40px, 5vw));
			}

			100% {
				clip-path: inset(0);
			}
		}

		@keyframes unmask-inner {
			0% {
				clip-path: inset(0 100% 0 0);
			}

			100% {
				clip-path: inset(0 0% 0 0);
			}
		}
	}
}

@mixin animation-initial-copy-element() {
	transform: translateY(25vh);
	opacity: 0;

	#{$noWebAnimationsClass} &,
	#{$noJsClass} & {
		animation: copy 750ms forwards;
		transition: all 500ms;
		transform: translateY(0);
		opacity: 1;

		@keyframes copy {
			0% {
				transform: translateY(25vh);
			}

			100% {
				transform: translateY(0);
			}
		}
	}
}

@mixin animation-initial-sticker($animation-direction: 'to-left') {
	picture {
		@if $animation-direction == 'to-left' {
			transform: translateX(100vw);
		} @else if $animation-direction == 'to-right' {
			transform: translateX(-100vw);
		}
	}

	#{$noWebAnimationsClass} &,
	#{$noJsClass} & {
		picture {
			transform: translateX(0);

			@if $animation-direction == 'to-left' {
				animation: picture-to-left 750ms forwards;
			} @else if $animation-direction == 'to-right' {
				animation: picture-to-right 750ms forwards;
			}
		}

		@keyframes picture-to-left {
			0% {
				transform: translateX(100vw);
			}

			100% {
				transform: translateX(0);
			}
		}

		@keyframes picture-to-right {
			0% {
				transform: translateX(-100vw);
			}

			100% {
				transform: translateX(0);
			}
		}
	}
}
