@use '../abstracts/variables' as *;

:root {
	// fonts
	--font-family-copy: #{$font-family-copy};
	--font-family-headline: #{$font-family-headlines-maiale};
	--font-lh-headline: 1.2;
	--font-weight-headline: bold;
	--font-size-headline-mobile: 26px;
	--font-size-headline-desktop: 44px;
	// colors
	--color-bg: #000;
	--color-image-bg: #2b2727;
	--color-text-default: #fff;
	--color-text-headline: #f2c36d;
	--color-highlight: #f3c571;
	--color-highlight-rgb: 243, 197, 113;
	--color-footer-bg: #323232;
	--color-footer-link: #fff;
	--gradient-headline: linear-gradient(
		90deg,
		#a16f24 0%,
		#a27025 0.16%,
		#d4ab60 5.31%,
		#f3d085 9.11%,
		#ffde93 11.12%,
		#b28236 22.87%,
		#dcb467 36.66%,
		#f3c571 50.99%,
		#c29649 65.2%,
		#f2c36d 84.17%,
		#c9a344 100%
	);
}

[class*='--theme-maiale-nero'] {
}

[class*='--theme-teneroso'] {
	// fonts
	--font-family-copy: #{$font-family-copy};
	--font-family-headline: #{$font-family-headlines-maiale};
	--font-lh-headline: 1.2;
	--font-weight-headline: bold;
	--font-size-headline-mobile: 26px;
	--font-size-headline-desktop: 44px;
	// colors
	--color-bg: #000;
	--color-image-bg: #2b2727;
	--color-text-default: #fff;
	--color-text-headline: #b41552;
	--color-highlight: #b41552;
	--color-highlight-rgb: 180, 21, 82;
	--color-footer-bg: #323232;
	--color-footer-link: #fff;
	--color-scrolldown: var(--color-highlight);
	--gradient-headline: linear-gradient(
		90deg,
		var(--color-text-headline) 0%,
		var(--color-text-headline) 100%
	);
}

[class*='--theme-bio-organico'] {
	// fonts
	--font-family-copy: #{$font-family-copy};
	--font-family-headline: #{$font-family-headlines-maiale};
	--font-lh-headline: 1.05;
	--font-weight-headline: bold;
	--font-size-headline-mobile: 26px;
	--font-size-headline-desktop: 44px;
	// colors
	--color-bg: #f3e8d1;
	--color-image-bg: #e5d8bc;
	--color-text-default: #000000;
	--color-text-headline: #00743b;
	--color-highlight: #00743b;
	--color-highlight-rgb: 0, 116, 59;
	--color-footer-bg: #323232;
	--color-footer-link: #fff;
	--color-scrolldown: var(--color-highlight);
	--gradient-headline: linear-gradient(
		90deg,
		var(--color-text-headline) 0%,
		var(--color-text-headline) 100%
	);
}
