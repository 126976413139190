@use 'abstracts' as *;

.copy {
	$self: &;
	overflow: hidden;

	&__headline {
		@include animation-initial-copy-element;
	}

	&__text {
		#{$self}__headline + & > p:first-child {
			margin-top: 2em;
		}
	}

	&__paragraph {
		@include animation-initial-copy-element;
	}
}
