@use 'abstracts' as *;

.grid {
	$self: &;

	&__section {
		width: 100%;
	}

	&__container {
		@include container-grid;
		margin: 0 5%;
		flex-grow: 1;

		@include media(lg) {
			width: 100%;
			max-width: 1100px;
			margin-right: auto;
			margin-left: auto;
		}
	}

	&__content {
		position: relative;

		&--background {
			height: 50vh;
		}

		&--media {
			grid-row: 1;
			grid-column: 1 / -1;
			height: 1px;
			position: relative;

			& > * {
				position: absolute;
			}
		}

		&--copy {
			grid-row: 1;
			min-height: 50vh;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding-block: 80px;

			@include media(sm) {
				padding-block: 60px;
			}
		}

		&--align-left {
			grid-column: 1 / -2;
			text-align: left;

			@include media(sm) {
				grid-column: 1 / -4;
			}
		}

		&--align-center {
			grid-column: 1 / -2;

			@include media(sm) {
				grid-column: 2 / -2;
				text-align: center;
			}
		}

		&--align-right {
			grid-column: 1 / -2;

			@include media(sm) {
				grid-column: 4 / -1;
				text-align: left;
			}
		}
	}
}
