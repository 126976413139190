@use 'abstracts' as *;

.footer {
	$self: &;

	background-color: var(--color-footer-bg);

	&__nav {
		display: flex;
		justify-content: center;
	}

	&__nav-link {
		@include typography-footer;
		padding: 12px 16px;

		&:hover,
		&:focus-visible {
			color: var(--color-highlight);
		}
	}
}
