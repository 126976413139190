@use '../abstracts' as *;
/*
* General helper classes
*/

// hide text while still being readable by screen readers
.helper-sr-only {
	@extend %helper-sr-only;
}

.helper-scroll-disabled {
	overflow-x: hidden !important;
	overflow-y: hidden !important;
}
