@use 'abstracts' as *;

.scrolldown {
	position: fixed;
	z-index: 100;
	bottom: 10px;
	position: fixed;
	bottom: 10px;
	left: 50%;
	transform: translateX(-50%);

	@include media(sm) {
		bottom: 40px;
	}

	&__button {
		background-color: transparent;
		width: 50px;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		transition:
			translate 0.3s,
			opacity 0.3s;
		transform: translateY(0);
		opacity: 1;
		color: #000;

		&--hidden {
			transform: translateY(80px);
			opacity: 0;
		}

		&::before,
		&::after {
			content: '';
			display: block;
			position: absolute;
			inset: 0;
			border-radius: 50%;
			transition: all 1.2s cubic-bezier(0.03, 0.7, 0.155, 1);
		}

		&::before {
			background-color: rgba(var(--color-highlight-rgb), 0.35);
			backdrop-filter: blur(2px);
		}

		&::after {
			background-color: rgba(var(--color-highlight-rgb), 1);
			inset: 14px;
		}

		&:hover::after {
			transform: scale(1.25);
		}

		svg {
			fill: currentColor;
			width: 12px;
			flex: 0 0 12px;
			margin-top: 1px;
			height: auto;
			position: relative;
			z-index: 3;

			@include media(sm) {
				width: 22px;
			}
		}
	}
}
