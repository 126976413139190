@use '../variables';

@use 'sass:map';
/*
    breakpoints usage:
    @include media(sm)          -> breakpoint min-width sm
    @include media(xl, down)    -> breakpoint max-width xl
    @include media(sm, xl)      -> breakpoint min-width sm, max-width xl
*/

@mixin media($breakpoint, $options: 'up', $breakpoint-to: 'xl') {
	@if map.get(variables.$breakpoints, $breakpoint) {
		@if $options == 'up' {
			@media screen and (min-width: #{map.get(variables.$breakpoints, $breakpoint)}) {
				@content;
			}
		}

		@if $options == 'down' {
			@media screen and (max-width: #{map.get(variables.$breakpoints, $breakpoint) - 1}) {
				@content;
			}
		}

		@if $options == 'to' {
			@media screen and (min-width: #{map.get(variables.$breakpoints, $breakpoint)}) and (max-width: #{map.get(variables.$breakpoints, $breakpoint-to) - 1}) {
				@content;
			}
		}
	} @else {
		@error 'No value found for `#{$breakpoint}`.';
	}
}
