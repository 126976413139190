@use 'abstracts' as *;

.background {
	$self: &;
	height: 50vh;
	position: absolute;
	inset: 0;
	overflow: hidden;
	clip-path: inset(min(40px, 5vw));
	background-color: var(--color-image-bg);
	@include animation-initial-background();

	&--loaded {
		#{$self} {
			&__inner {
				clip-path: inset(0 100% 0 0);
			}
		}
	}

	&__inner {
		position: absolute;
		inset: 0;
		clip-path: inset(0 99.5% 0 0);
	}

	// container for secondary image that will be displayed using contain
	&__secondary {
		position: absolute;
		inset: 20px;

		.media img.media__img {
			object-fit: contain;
		}
	}

	& .media {
		position: absolute;
		display: block;
		inset: 0;
		object-fit: cover;
		z-index: 1;
		opacity: 0.01;

		.media__picture {
			display: block;
			position: absolute;
			inset: 0;
		}

		.media__img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&--loaded .media {
		opacity: 1;
	}
}
