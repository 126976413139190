@use 'abstracts' as *;

.sticker {
	position: absolute;
	top: 0;
	width: 50%;
	transform: translateY(-50%);

	@include media(sm) {
		// width: 25%;
		width: 25%;
	}

	@include media(lg) {
		width: 33%;
	}

	&--align-right {
		right: 0;
	}

	&--align-left {
		right: 0;

		@include media(sm) {
			right: auto;
			left: 0;
		}
	}

	&--animation-direction-to-right {
		@include animation-initial-sticker('to-left');

		@include media(sm) {
			@include animation-initial-sticker('to-right');
		}
	}

	&--animation-direction-to-left {
		@include animation-initial-sticker('to-left');
	}
}
