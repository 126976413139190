@use 'abstracts' as *;

.header {
	$self: &;
	margin-left: 20px;
	padding: 20px 15px 10px;
	position: absolute;
	top: 0;
	left: 0;
	width: 80px;
	background-color: #000;
	opacity: 0;
	animation: fadeIn 3s forwards 1.5s;
	animation-timing-function: cubic-bezier(0.2, 0, 0, 1);

	@include media(sm) {
		margin-left: 40px;
	}

	@keyframes fadeIn {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	&__image {
		display: block;
		width: 51px;
		height: auto;
	}
}
