@use 'sass:map';
/**
 * Create most important global zIndex values in ascending order
 * Last classname in list gets highest z-index
 */
$z-index-list: ('.main', '.scrolldown', '.dots', '.background', '.sticker', '.copy', '.header');

$z-index-defaults: ();

// create all z-index values
$temp-z: 10;

@each $class in $z-index-list {
	#{$class} {
		@if map.has-key($z-index-defaults, $class) {
			z-index: map.get($z-index-defaults, $class);
			$temp-z: map.get($z-index-defaults, $class) !global;
		} @else {
			z-index: $temp-z + 10;
			$temp-z: $temp-z + 10 !global;
		}
	}
}
