@use '../abstracts/variables';
@use '../abstracts/mixins' as *;

h1 {
	@include typography-h1;
}

h2 {
	@include typography-h2;
}

p {
	@include typography-copy;

	& + & {
		margin-top: 1.5em;
	}

	h1 + &,
	h2 + &,
	h3 + & {
		margin-top: 2em;
	}
}
