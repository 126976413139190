@use '../variables';
@use 'media';

/*
* Headlines
*/
@mixin typography-gradient {
	background: var(--color-text-headline);
	background: var(--gradient-headline);

	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

@mixin typography-headline-base {
	font-size: inherit;
	line-height: inherit;
	// hyphens: auto;
	word-break: normal;
	line-break: auto;
	color: var(--color-text-headline);
	font-family: var(--font-family-headline);
	font-weight: var(--font-weight-headline);
}

@mixin typography-h1 {
	@include typography-headline-base;
	@include typography-gradient;
	font-size: var(--font-size-headline-mobile);
	line-height: var(--font-lh-headline);

	@include media.media(lg) {
		font-size: var(--font-size-headline-desktop);
	}
}

@mixin typography-h2 {
	@include typography-headline-base;
	@include typography-gradient;
	font-size: var(--font-size-headline-mobile);
	line-height: var(--font-lh-headline);

	@include media.media(lg) {
		font-size: var(--font-size-headline-desktop);
	}
}

@mixin typography-copy {
	color: var(--color-text-default);
	font-familty: var(--font-family-copy);
	font-weight: 500;
	font-size: 15px;
	line-height: 1.4;

	@include media.media(lg) {
		font-size: 18px;
	}
}

@mixin typography-footer {
	color: var(--color-footer-link);
	font-familty: var(--font-family-copy);
	font-weight: 500;
	font-size: 13px;
	line-height: 1.4;
	transition: color 0.1s;

	@include media.media(lg) {
		font-size: 18px;
	}
}
