/**
 * font face declarations
 */
@font-face {
	font-family: 'SchmalhansOT';
	font-weight: bold;
	font-style: normal;
	src:
		url('../fonts/SchmalhansOT-Bold.woff2') format('woff2'),
		url('../fonts/SchmalhansOT-Bold.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'ClanPro';
	font-weight: 500;
	font-style: normal;
	src:
		url('../fonts/ClanPro-News.woff2') format('woff2'),
		url('../fonts/ClanPro-News.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'ClanPro';
	src:
		url('../fonts/ClanPro-Bold.woff2') format('woff2'),
		url('../fonts/ClanPro-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}
