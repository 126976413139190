@use '../abstracts/variables';
@use '../abstracts/mixins' as mixin;

html {
	scroll-behavior: smooth;
}

body {
	min-height: 100%;
	background-color: var(--color-bg);
	position: relative;
	font-family: var(--font-family-copy);
}

a,
button:not([disabled]),
input,
label,
select {
	&:focus-visible {
		outline: none;
		box-shadow: 0 0 0 5px var(--color-highlight);
	}
}

a,
button,
input[type='submit'] {
	position: relative;
	display: inline-block;
	font: inherit;
	font-family: var(--font-family-copy);
	color: inherit;
	text-align: left;
	text-decoration: none;
	cursor: pointer;
	background-color: transparent;
	touch-action: manipulation;
	overflow: visible;

	&:hover,
	&:focus,
	&:active {
		outline: 0;
	}
}

i,
em {
	font-style: italic;
}

b,
strong {
	font-weight: bold;
}
